
import { defineComponent, computed, ref } from "vue";
import SendReceiptButton from "@/components/Button.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { post } from "@/utils/http";
import { useStore } from "vuex";
export default defineComponent({
  name: "SendEmailReceipt",
  components: {
    SendReceiptButton,
    ModalComponent,
    ConfirmDialog,
    PoweredByFooter,
    LoadingIcon
  },
  props: {
    emailData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const email = ref("");
    const title = ref("");
    const body = ref("");
    const sent = ref(false);
    const sending = ref(false);
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const validEmailAddress = computed(() => emailRegex.test(email.value));
    const store = useStore();
    const hideModal = () => {
      sent.value = false;
    };

    const sendReceipt = async () => {
      if (validEmailAddress.value) {
        sending.value = true;
        post(
          props.emailData.actionUrl,
          {
            emailAddress: email.value
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.user.currentUser.token}`
            }
          }
        )
          .then(() => {
            title.value = "Sent Successfully.";
            body.value = "Your payment receipt has been sent to your email.";
            sending.value = false;
            sent.value = true;
          })
          .catch(async () => {
            title.value = "Error";
            body.value = "Unable to send receipt, please try again later.";
            sending.value = false;
            sent.value = true;
          });
      } else {
      }
    };
    return {
      email,
      validEmailAddress,
      sendReceipt,
      sent,
      hideModal,
      sending,
      title,
      body,
      close: computed(() => props.emailData.onExit)
    };
  }
});
