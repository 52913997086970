import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import HomePage from "@/pages/Home.vue";
import PaymentPage from "@/pages/Payment.vue";
import NotFound from "@/pages/NotFound.vue";

const routes: Array<RouteRecordRaw> = [


  {
    path: "/:pos/:site/:table",
    component: HomePage,

  },
  {
    path: "/payments/:payment",
    name: 'PaymentPage',
    component: PaymentPage,

  },
  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    component: NotFound
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
