
import { onSnapshot, doc, getDoc, getFirestore } from "firebase/firestore";

export default function listenOnPaymentUpdate(document: string, documentId: string, store: any, handler?: (docData: any) => void, onError?: (error: any) => void) {
  const db = getFirestore();
  const paymentCollection = doc(db, "customers", store.state.user.currentUser.uid);

  return onSnapshot(doc(paymentCollection, document, documentId), doc => {
    if (handler !== undefined) {
      handler(doc.data());
    }
  },
    error => {
      if (onError !== undefined) {
        onError(error);
      }
    }
  );
}
