const UnexpectedError = {
    title: "Error",
    message: "An unexpected error has occurred"
};
const PaymentNotFoundError = {
    title: "Error",
    message: "Payment not found"
}
const PaymentUnauthorizedError = {
    title: "Error",
    message: "You are not authorized to view this payment"
}
const UnauthorizedUserError = {
    title: "Error",
    message: "You do not have permission to access this page"
}
export { UnexpectedError, PaymentNotFoundError, PaymentUnauthorizedError, UnauthorizedUserError };