
import { defineComponent, computed, PropType } from "vue";
export default defineComponent({
  name: "ProgressBar",
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },
  setup(props) {
    const progress = computed(() => {
      if (props.order != null) {
        const width =
          ((props.order.total.amount - props.order.remaining.amount) /
            props.order.total.amount) *
          100;
        return `width:${width}%`;
      }
      return "width:0";
    });
    return {
      progress
    };
  }
});
