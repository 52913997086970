
type State = {
    currentUser: User
}
const state = () => ({
    currentUser: null
})

const getters = {}

const actions = {
    updateUser({ commit }: { commit: (...args: any[]) => void }, userData: User) {
        commit('updateUser', userData)
    }
}

const mutations = {
    updateUser(state: State, userData: User) {
        state.currentUser = userData;
    }
}
export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}