
import { defineComponent, PropType } from "vue";
type ButtonColor = "orange" | "black";
export default defineComponent({
  name: "HomeButton",
  props: {
    buttonText: {
      type: String,
      required: true
    },
    handler: {
      type: Function,
      required: false
    },
    color: {
      type: String as PropType<ButtonColor>,
      required: false
    }
  }
});
