
import Storage from '@/utils/storage';

type State = {
    order: Order,
    processingPayment: boolean,
    paymentAmount: number,
    split: boolean,
    payment: Payment
}
const orderStorage = new Storage<Order>('order')
const amountStorage = new Storage<number>('amount')

const state = () => ({
    order: orderStorage.get(),
    processingPayment: false,
    paymentAmount: amountStorage.get(),
    split: false
})

const getters = {}

const actions = {
    updateSplitStatus({ commit }: { commit: (...args: any[]) => void }, split: boolean) {
        commit('updateSplitStatus', split);
    },
    updatePaymentAmount({ commit }: { commit: (...args: any[]) => void }, amount: number) {
        commit('updatePaymentAmount', amount);
    },
    updateOrder({ commit, state }: { commit: (...args: any[]) => void, state: State }, orderData: Order) {
        commit('updateOrder', orderData);
        commit('updatePaymentAmount', orderData.remaining.amount);
    }
}

const mutations = {
    updateOrder(state: State, orderData: Order) {
        if (orderData != undefined && orderData != null) {

            orderStorage.set(orderData);
            state.order = orderData;

        }
    },
    updateSplitStatus(state: State, split: boolean) {
        state.split = split;
    },
    updatePaymentAmount(state: State, amount: number) {
        amountStorage.set(amount);
        state.paymentAmount = amount;
    },
    updatePaymentProcessingStatus(state: State, status: boolean) {
        state.processingPayment = status;
    }
}
export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}