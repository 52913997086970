
import { defineComponent } from "vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";

export default defineComponent({
  name: "NotFoundPage",
  components: {
    PoweredByFooter
  }
});
