
import OrderInfo from "@/components/OrderInfo.vue";
import PaymentFooterSticky from "@/components/PaymentFooterSticky.vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "OrderComponent",
  components: {
    OrderInfo,
    PaymentFooterSticky
  },
  setup() {
    const store = useStore();

    return {
      order: computed(() => store.state.order)
    };
  }
});
