
import LoadingIcon from "@/components/LoadingIcon.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ErrorComponent from "@/components/ErrorComponent.vue";
import OrderComponent from "@/components/OrderComponent.vue";
import { login } from "@/services/auth/login";
import { getOrder } from "@/services/order/getOrder";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import * as errors from "@/utils/errors";
export default defineComponent({
  name: "HomePage",

  components: {
    OrderComponent,
    ErrorComponent,
    ModalComponent,
    LoadingIcon
  },
  setup() {
    const loading = ref(true);
    const store = useStore();
    const route = useRoute();
    const orderComponent = ref("");
    const error = ref({});
    onMounted(async () => {
      const user = await login();
      await store.dispatch("updateUser", user);
      getOrder(
        store.state.user.currentUser.token,
        route.params.pos as string,
        route.params.site as string,
        route.params.table as string
      )
        .then(async (order: Order | OrderUnavailable) => {
          if ("reason" in order) {
            error.value = {
              title: order.reason.title,
              message: order.reason.message
            };
            orderComponent.value = "ErrorComponent";
          } else {
            await store.dispatch("updateOrder", {
              ...order,
              pos: route.params.pos,
              table: route.params.table
            });
            orderComponent.value = "OrderComponent";
          }
          loading.value = false;
        })
        .catch(async err => {
          if (err.message == "401") {
            error.value = errors.UnauthorizedUserError;
            orderComponent.value = "ErrorComponent";
          } else {
            error.value = errors.UnexpectedError;
            orderComponent.value = "ErrorComponent";
          }

          loading.value = false;
        });
    });

    return {
      orderComponent,
      error,
      loading
    };
  }
});
