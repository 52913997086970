
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "LoadingIcon",
  props: {
    speed: {
      type: Number,
      required: false
    },
    mode: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const style = computed(
      () =>
        `border-color: ${
          props.color
        } transparent transparent transparent;animation: loading-icon ${
          props.speed ? props.speed / 1000 : 1.2
        }s ${
          props.mode ? props.mode : "cubic-bezier(0.5, 0, 0.5, 1)"
        } infinite;`
    );

    return {
      style
    };
  }
});
