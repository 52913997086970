import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "button" }
const _hoisted_2 = {
  key: 0,
  class: "orange"
}
const _hoisted_3 = {
  key: 1,
  class: "black"
}
const _hoisted_4 = {
  key: 2,
  class: "gray"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.color=='orange')
      ? (_openBlock(), _createElementBlock("button", _hoisted_2, _toDisplayString(_ctx.buttonText), 1))
      : _createCommentVNode("", true),
    (_ctx.color=='black')
      ? (_openBlock(), _createElementBlock("button", _hoisted_3, _toDisplayString(_ctx.buttonText), 1))
      : _createCommentVNode("", true),
    (_ctx.color=='gray')
      ? (_openBlock(), _createElementBlock("button", _hoisted_4, _toDisplayString(_ctx.buttonText), 1))
      : _createCommentVNode("", true)
  ]))
}