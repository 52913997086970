import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_icon = _resolveComponent("loading-icon")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.paymentStatusComponent), {
          key: 0,
          error: _ctx.error,
          payment: _ctx.paymentRecord,
          emailData: _ctx.emailData,
          onSend: _ctx.onSend
        }, null, 8, ["error", "payment", "emailData", "onSend"]))
      : _createCommentVNode("", true),
    _createVNode(_component_modal_component, { visible: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_loading_icon, {
          speed: 1000,
          mode: 'linear',
          color: '#ff5e00'
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}