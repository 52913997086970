
import { defineComponent } from "vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
import RetryButton from "@/components/Button.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PaymentFailed",
  components: {
    PoweredByFooter,
    RetryButton
  },
   props: {
    error: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const retry = async () => {
      await router.go(-1);
    };
    return {
      retry
    };
  }
});
