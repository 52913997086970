
import {
  defineComponent,
  onMounted,
  reactive,
  PropType,
  watch,
  computed,
  ref
} from "vue";
import { services } from "../utils/services";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { post } from "@/utils/http";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ApplePayButton from "@/components/ApplePayButton.vue";
import GooglePayButton from "@/components/GooglePayButton.vue";
import PayWithPayoButton from "@/components/PayWithPayoButton.vue";
import { PaymentRequest } from "@stripe/stripe-js";
import { encode } from "@/utils/base64";
import Modal from "@/components/ModalComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default defineComponent({
  name: "PaymentButtons",
  components: {
    LoadingIcon,
    ApplePayButton,
    GooglePayButton,
    PayWithPayoButton,
    Modal,
    ConfirmDialog
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },
  setup(props) {
    const paymentMethodsAvailable = reactive({
      applePay: false,
      googlePay: false
    });
    const creatingPayment = ref(false);
    const initialising = ref(true);
    const router = useRouter();
    const store = useStore();
    const paymentUnderMinimum = ref(false);
    const paymentButtonRequest = ref<PaymentRequest>();
     const hideModal = () => {
      paymentUnderMinimum.value = false;
    };
    const showPayment = () => {
    
      if (
        paymentButtonRequest.value != undefined &&
        store.state.order.paymentAmount > 0
      ){
        if( store.state.order.paymentAmount<350){
          paymentUnderMinimum.value=true;
        }
         else{
        paymentButtonRequest.value.show();
      }
      }
     
    };
    const createPaymentRequest = async () => {
      creatingPayment.value = true;
      const { stripe } = await services;
      if (stripe !== null) {
        const paymentRequest = stripe.paymentRequest({
          country: "AU",
          currency: props.order.remaining.currency, //update to money.currency
          total: {
            label: props.order.site.name,
            amount: store.state.order.paymentAmount
          },
          requestPayerName: false,
          requestPayerEmail: false
        });

        paymentButtonRequest.value = paymentRequest;
        const result = await paymentRequest.canMakePayment();
        if (result !== null) {
          creatingPayment.value = false;

          Object.assign(paymentMethodsAvailable, result);
          // prButton.mount("#buttons-wrapper");

          paymentRequest.on("paymentmethod", async ev => {
            store.commit("updatePaymentProcessingStatus", true);
            const payment: Payment = await post(
              props.order.$actions.pay,
              {
                order: { provider: props.order.pos, id: props.order.id },
                method: {
                  provider: "stripe",
                  method: ev.paymentMethod.id
                },
                amount: {
                  amount: store.state.order.paymentAmount,
                  currency: store.state.order.order.remaining.currency
                }
              },
              {
                headers: {
                  Authorization: `Bearer ${store.state.user.currentUser.token}`
                }
              }
            );
            ev.complete("success");

            await router.push({
              name: "PaymentPage",
              params: { payment: encode(payment.$self) }
            });
          });
        } else {
          creatingPayment.value = false;
        }
      }
    };
    onMounted(async () => {
      await createPaymentRequest();
      initialising.value = false;
    });
    watch(
      () => store.state.order.paymentAmount,
      async () => {
        await createPaymentRequest();
      }
    );
    return {
      paymentMethodsAvailable,
      creatingPayment,
      initialising,
      showPayment,
      paymentUnderMinimum,
      hideModal,
      canPay: computed(
        () =>
          paymentMethodsAvailable.applePay || paymentMethodsAvailable.googlePay
      )
    };
  }
});
