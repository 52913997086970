export const parsePayment = (uri: string) => {
    const protocol = uri.split(':')[0];
    const location = uri.split(':')[1];
    const customer = location.split('/')[1];
    const payment = location.split('/').slice(2).join('/');
    const doc = payment.split('/')[0];
    const id = payment.split('/')[1];
    return {
        protocol,
        customer,
        doc,
        id
    }
}