import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "payment-buttons" }
const _hoisted_2 = {
  key: 0,
  class: "payment-methods-unavailable"
}
const _hoisted_3 = {
  key: 1,
  class: "loading"
}
const _hoisted_4 = {
  class: "buttons-wrapper",
  id: "buttons-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_icon = _resolveComponent("loading-icon")!
  const _component_pay_with_payo_button = _resolveComponent("pay-with-payo-button")!
  const _component_apple_pay_button = _resolveComponent("apple-pay-button")!
  const _component_google_pay_button = _resolveComponent("google-pay-button")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.initialising && !_ctx.canPay)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "To make a payment, please set up Google Pay/Apple Pay."))
      : _createCommentVNode("", true),
    (_ctx.creatingPayment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_loading_icon, {
            speed: 1000,
            mode: 'linear',
            color: '#cccccc'
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      false
        ? (_openBlock(), _createBlock(_component_pay_with_payo_button, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.paymentMethodsAvailable.applePay)
        ? (_openBlock(), _createBlock(_component_apple_pay_button, {
            key: 1,
            onClick: _ctx.showPayment
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.paymentMethodsAvailable.googlePay)
        ? (_openBlock(), _createBlock(_component_google_pay_button, {
            key: 2,
            onClick: _ctx.showPayment
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_modal, { visible: _ctx.paymentUnderMinimum }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          title: 'Minimum payment of $3.50 required',
          body: 'For payment under $3.50, please pay directly at the venue.',
          buttons: 
        [{
            text:'Ok',
            handler: _ctx.hideModal
               
        }] 
        }, null, 8, ["title", "body", "buttons"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}