
import { defineComponent } from "vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
export default defineComponent({
  name: "PaymentProcessing",
  components: {
    LoadingIcon,
    PoweredByFooter
  }
});
