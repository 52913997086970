
import { defineComponent } from "vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
export default defineComponent({
  name: "ErrorComponent",
  components: {
    PoweredByFooter
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  }
});
