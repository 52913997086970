import { createStore } from 'vuex';
import order from '@/store/order';
import user from '@/store/user';

export default createStore({

  modules: {
    order,
    user
  },
});
