
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SplitBill",
  setup() {
    const active = ref(false);
    const amount = ref("");
    const splitAmount = ref("");
    const message = ref("");
    const style = computed(() =>
      active.value ? "height:154px" : "height:24px"
    );
    const store = useStore();
    const activateSplit = () => {
      store.dispatch("updateSplitStatus", true);
      active.value = true;
    };
    const deactiveSplit = () => {
      store.dispatch("updateSplitStatus", false);
       store.dispatch("updatePaymentAmount", store.state.order.order.remaining.amount);
      active.value = false;
    };
    const onEnterAmount = () => {
      splitAmount.value="";
      if (amount.value == "") {
        store.dispatch("updatePaymentAmount", 0);
        message.value = "Please enter the amount";
      } else if (isNaN(Number(amount.value))) {
        store.dispatch("updatePaymentAmount", 0);
        message.value = "Please enter a valid amount";
      } else if (
        Number(amount.value) >
        store.state.order.order.remaining.amount / 100
      ) {
        store.dispatch("updatePaymentAmount", 0);
        message.value = "Amount can not be greater than total.";
      } else if (Number(amount.value) < 3.5) {
        store.dispatch("updatePaymentAmount", 0);
        message.value = "Min. payment of $3.50 required. For payment under $3.50, please pay directly at venue.";
      } else if (
        amount.value.indexOf(".") > -1 &&
        amount.value.split(".")[1].length > 2
      ) {
        store.dispatch("updatePaymentAmount", 0);
        message.value = "Please enter an amount with max 2 decimal places";
      } else {
        splitAmount.value = `(\$${Number(amount.value).toFixed(2)})`;
        store.dispatch("updatePaymentAmount", Number(amount.value) * 100);
      }
    };
    const inputStyle = computed(() =>
      amount.value == "" ? "padding-left:0;" : "padding-left:12px"
    );
    return {
      active,
      style,
      inputStyle,
      amount,
      splitAmount,
      activateSplit,
      deactiveSplit,
      message,
      onEnterAmount
    };
  }
});
