
import { defineComponent, computed, ref, onMounted, reactive } from "vue";
import listenOnPaymentUpdate from "@/composables/useFirestore";
import { login } from "@/services/auth/login";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import * as errors from "@/utils/errors";
import PaymentComplete from "@/components/PaymentComplete.vue";
import PaymentFailed from "@/components/PaymentFailed.vue";
import PaymentProcessing from "@/components/PaymentProcessing.vue";
import SendEmailReceipt from "@/components/SendEmailReceipt.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ErrorComponent from "@/components/ErrorComponent.vue";
import { decode } from "@/utils/base64";
import { parsePayment } from "@/utils/uri-parser";

export default defineComponent({
  name: "PaymentPage",
  components: {
    PaymentComplete,
    PaymentFailed,
    PaymentProcessing,
    SendEmailReceipt,
    ModalComponent,
    ErrorComponent,
    LoadingIcon
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const status = ref("processing");
    const loading = ref(true);
    const error = ref({});
    const paymentRecord = ref(null);
    const paymentStatusComponent = ref("");
    const onSend = () => {
      paymentStatusComponent.value = "SendEmailReceipt";
    };
    const emailData = reactive({
      onExit: () => {
        paymentStatusComponent.value = "PaymentComplete";
      },
      actionUrl: ""
    });
    const castingStatus = (status: string) => {
      return status == "complete" || status == "failed" ? status : "processing";
    };
    const onPaymentUpdate = async (payment: any) => {
      if (payment == undefined) {
        error.value = errors.PaymentNotFoundError;
        paymentStatusComponent.value = "ErrorComponent";
      } else {
        paymentRecord.value = payment;
        emailData.actionUrl =
          payment.$actions == undefined ? "" : payment.$actions["send-receipt"];

        status.value = castingStatus(payment.status);
        if(payment.status=="failed")
        error.value = {title: payment.failure.cause,message:payment.failure.message};
        paymentStatusComponent.value =
          "Payment" +
          status.value.charAt(0).toUpperCase() +
          status.value.slice(1).toLowerCase();
      }
      loading.value = false;
    };
    const onPaymentUpdateError = async (error: any) => {
      error.value = errors.UnexpectedError;
      paymentStatusComponent.value = "ErrorComponent";
      loading.value = false;
    };
    onMounted(async () => {
      if (route.params.payment != undefined) {
        const user = await login();
        await store.dispatch("updateUser", user);
        const paymentURI = decode(route.params.payment as string);
        if (paymentURI === false) {
          error.value = errors.PaymentNotFoundError;
          paymentStatusComponent.value = "ErrorComponent";
          loading.value = false;
        } else {
          const { doc, id } = parsePayment(paymentURI);
          listenOnPaymentUpdate(
            doc,
            id,
            store,
            onPaymentUpdate,
            onPaymentUpdateError
          );
        }
      } else {
        paymentStatusComponent.value = "ErrorComponent";
        error.value = errors.PaymentNotFoundError;
      }
    });

    return {
      loading,
      order: computed(() => store.state.order),
      paymentStatusComponent,
      error,
      paymentRecord,
      onSend,
      emailData
    };
  }
});
