
import { defineComponent, PropType, computed } from "vue";
import ProgressBar from "@/components/ProgressBar.vue";
export default defineComponent({
  name: "OrderInfo",
  components: {
    ProgressBar
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },
  setup(props) {
    const showProgressBar = computed(
      () => props.order.total.amount > props.order.remaining.amount
    );
    return {
      showProgressBar,
      payable: computed(() => props.order.remaining.amount>=3.5)
    };
  }
});
