import { apiBaseUrl } from "@/utils/base-url";
import { get } from "@/utils/http";
export async function getOrder(
  token: string,
  pos: string,
  site: string,
  table: string
): Promise<Order | OrderUnavailable> {
  return get(`${apiBaseUrl}/${pos}/${site}/${table}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
