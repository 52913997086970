
import { defineComponent, PropType, computed } from "vue";
import PaymentButtons from "@/components/PaymentButtons.vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
import SplitBill from "@/components/SplitBill.vue";
export default defineComponent({
  name: "PaymentFooterSticky",

  components: {
    PaymentButtons,
    PoweredByFooter,
    SplitBill
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    },
    sticky: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const stickyStyle = computed(() =>
      props.sticky ? "position: fixed;bottom: 0;margin-top:163px" : ""
    );
    return {
      stickyStyle
    };
  }
});
