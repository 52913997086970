import { apiBaseUrl } from "@/utils/base-url";
import { loadStripe } from "@stripe/stripe-js";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { get } from "./http";

type Settings = {
  stripe: {
    key: string;
  };
  firebase: {
    config: {
      apiKey: string;
      authDomain: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
    };
  };
  environment: {
    sandbox: boolean;
  };
};

const settings = async (): Promise<Settings> => {
  // TODO: KAO - inject via config
  const url = `${apiBaseUrl}/client/settings.json`;
  return await get(url);
};

const init = async (settings: Settings) => {
  const fb = initializeApp(settings.firebase.config);

  const db = getFirestore(fb);
  const auth = getAuth(fb);

  const stripe = await loadStripe(settings.stripe.key);

  return { db, auth, stripe };
};

export const services = settings().then(init);
