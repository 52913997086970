
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "ModalComponent",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
});
