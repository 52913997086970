import { services } from "@/utils/services";
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";

export async function login() {
  const { auth } = await services;

  const { user } = await signInAnonymously(auth);
  const token = await user.getIdToken();

  return {
    token,
    uid: user.uid
  };
}
