
import { defineComponent, PropType } from "vue";
interface Button {
  text: string;
  handler: () => void;
}
export default defineComponent({
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    buttons: {
      type: Array as PropType<Array<Button>>,
      required: true
    }
  }
});
