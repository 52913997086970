
import { defineComponent } from "vue";
import SendReceiptButton from "@/components/Button.vue";
import PoweredByFooter from "@/components/PoweredByFooter.vue";
export default defineComponent({
  name: "PaymentComplete",
  components: {
    SendReceiptButton,
    PoweredByFooter
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
    onSend: {
      type: Function,
      required: true
    }
  }
});
