const ENC = {
    '+': '-',
    '/': '_',
    '=': '.'
}
const DEC = {
    '-': '+',
    _: '/',
    '.': '='
}

export const encode = (data: string) => {
    return btoa(data).replace(/[+/=]/g, (m: string) => (ENC as any)[m]);
}

export const decode = (data: string) => {
    try {
        return atob(data.replace(/[-_.]/g, (m: string) => (DEC as any)[m]));
    }
    catch (e) {
        return false;
    }
}